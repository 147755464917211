import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { serviceContainer, serviceItem } from "../../components/Animations/Variants";
import Card from "./Card";
import { Service } from "./Service";
import {
    SectionTitle, ServicesContainer,
    ServiceSectionsContainer,
    ServicesTitleContainer
} from "./ServiceSectionContainer";
import { ServiceTypes } from "./ServiceTypes";

const services: Service[] = [
    {
        image: require('../../assets/consultancy/office-1209640_1920-768x512.jpg'),
        imageAlt: ServiceTypes.Consultancy,
        title: ServiceTypes.Consultancy,
        extra: 'In Update'
    },
    {
        image: require('../../assets/training/digital-marketing-1725340_1920-768x512.jpg'),
        imageAlt: ServiceTypes.Training,
        title: ServiceTypes.Training,
        extra: 'In Update'
    },
    {
        image: require('../../assets/engineering-services/motor-768750_1920-768x512.jpg'),
        imageAlt: ServiceTypes.EngineeringServices,
        title: ServiceTypes.EngineeringServices,
        extra: 'In Update'
    },
];

const ServicesSection = () => {
    const { t, i18n } = useTranslation();

    return (
        <ServiceSectionsContainer>
            <ServicesTitleContainer>
                <SectionTitle>{t('Service Title')}</SectionTitle>
            </ServicesTitleContainer>
            <ServicesContainer
                variants={serviceContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true }}
            >
                {services.map((service, index) => (
                    <motion.div variants={serviceItem} key={index}>
                        <Card {...service} />
                    </motion.div>
                ))}
            </ServicesContainer>
        </ServiceSectionsContainer>
    );
}

export default ServicesSection;