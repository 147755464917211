import { useTranslation } from "react-i18next";
import { FadeInWhenVisible, OnHoverZoom } from "../../components/Animations/Motions";
import { ourMissionLogo } from "../../components/Constants/Images";
import { Column1, Column2, Heading, Img, ImgWrapper, SectionContainer, SectionRow, Subtitle, TextWrapper } from "../Section/SectionContainer";

const OurMission = () => {
    const {t, i18n} = useTranslation();
    const imageAtStart = true;
    const centerSection = true;
    const lightBg = true;
    const lightText = false;

    const theCompany = t('The Company');
    const description = [
        t('Our Mission Explained'),
        t('Our Mission Training'),
    ];
    const imageAlt = t('Our Mission');

    return (
        <SectionContainer 
            lightBg={lightBg} 
            verticalCenter={centerSection}
            fixHeightBelow745px={true}
        >
            <FadeInWhenVisible>
                <SectionRow imgStart={imageAtStart}>
                    <Column1 imgStart={imageAtStart}>
                        <OnHoverZoom>
                            <TextWrapper>
                                <Heading lightText={lightText}>
                                    {theCompany}
                                </Heading>
                                {description.map((line, index) => (
                                    <Subtitle key={index} darkText={!lightText}>
                                        {line}
                                    </Subtitle>
                                ))}
                            </TextWrapper>
                        </OnHoverZoom>
                    </Column1>
                    <Column2 imgStart={imageAtStart}>
                        <OnHoverZoom>
                            <ImgWrapper>
                                <Img src={ourMissionLogo} alt={imageAlt} />
                            </ImgWrapper>
                        </OnHoverZoom>
                    </Column2>
                </SectionRow>
            </FadeInWhenVisible>
        </SectionContainer>
    );
}

export default OurMission;