import { motion } from "framer-motion";
import styled from "styled-components";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { Palette } from "../../components/Constants/Palette";

export const SectionContainer = styled.div<{ 
  lightBg: boolean, 
  verticalCenter: boolean, 
  smallScreenHeight?: string,
  fitContent?: boolean,
  fixHeightBelow745px?: boolean
}>`
  color: #fff;
  background-color: ${prop => (prop.lightBg ? Palette.lightGray : Palette.backgroundDarkBlue)};
  font-weight: bold;
  height: ${prop => prop.fitContent ? 'fit-content !important' : '100vh'};
  width: 100%;
  justify-content: center;
  padding: 80px 0;
  ${({ verticalCenter }) =>
    verticalCenter ? `{
      display: flex;
      align-items: center;
    }`: null};

  ${({ fixHeightBelow745px }) => 
    fixHeightBelow745px ? `{
      @media screen and (max-height: 745px) {
        height: auto;
      }
    }`: null};

  @media screen and (min-height: 746px) and (max-width: 1288px) {
    height: ${prop => prop.smallScreenHeight ? prop.smallScreenHeight : '700px'};
    padding: 100px 0;
  }
  @media screen and (max-width: 960px) {
    padding: 80px 0;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 0;
    height: auto;
  }
  a {
    text-decoration: none;
  }
`;

export const IconContainer = styled(motion.div)`
  position: absolute;
  right: 50px;
  visibility: visible;
  bottom: 100px;
  @media screen and (max-width: 1111px) {
    right: 20px;
  }
  @media screen and (max-width: 1288px) {
    visibility: hidden;
  }
`;

export const AnimateIconDown = styled(motion.div)`
  cursor: pointer;
`;

export const Icon = styled(BsFillArrowDownCircleFill)`
  font-size: 5rem;
  @media screen and (max-width: 1111px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
  fill: ${Palette.opaqueBlue};

  &:hover {
    fill: ${Palette.blue};
  }
`;

export const SectionWrapper = styled.div`
  @media screen and (min-width: 768px) {
    height: 100vh;
    width: 100%;
  }
  display: grid;
  z-index: 1;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
`;

export const SectionRow = styled.div<{
  imgStart: boolean;
}>`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  grid-gap: 50px;
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div<{
  imgStart: boolean;
}>`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  display: flex;
  justify-content: ${({ imgStart }) =>
    imgStart ? 'left' : 'right'};
`;

export const Column2 = styled.div<{
  imgStart: boolean;
}>`
  margin: 15px 0;
  padding: 0 15px;
  grid-area: col2;
  display: flex;
  justify-content: ${({ imgStart }) =>
    imgStart ? 'right' : 'left'};
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
`;

export const Heading = styled.h1<{
  lightText: boolean;
}>`
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 700;
  color: ${({ lightText }) => (lightText ? Palette.white : Palette.black)};

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 26px;
  }
`;

export const Subtitle = styled.p<{
  darkText: boolean;
}>`
  max-width: 440px;
  margin-bottom: 35px;
  font: 18px Calibri, sans-serif;
  // font-size: 18px;
  line-height: 24px;
  text-align: justify;
  text-justify: inter-word;
  color: ${({ darkText }) => (darkText ? "#010606" : "#f7f8fa")};

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  :last-child {
    margin-bottom: 0px;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  height: 100%;
  @media screen and (max-width: 768px) {
    max-width: 450px;
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding: 0;
  border-radius: 5px;
  max-height: 400px;

  @media screen and (max-width: 768px) {
    max-height: 300px;
  }
`;