import { motion } from "framer-motion";
import styled from "styled-components";
import { Palette } from "../../components/Constants/Palette";

export const ServiceSectionsContainer = styled.div`
  min-height: 100vh;
  background-color: ${Palette.backgroundDarkBlue};
`;

export const ServicesTitleContainer = styled.div`
  height: 10%;
  padding: 10rem 0 2rem 0;
  text-align: center;
`;

export const ServicesContainer = styled(motion.div)`
  padding: 2rem;
  height: 90%;
  display: grid;
  grid-template-columns: repeat(3, minmax(350px, 350px));
  gap: 4rem 4rem;
  justify-content: center;

  @media screen and (max-width: 1423px) {
    gap: 2rem 2rem;
    grid-template-columns: repeat(3, minmax(350px, 350px));
  }

  @media screen and (max-width: 1280px) {
    gap: 4rem 4rem;
    grid-template-columns: repeat(1, minmax(350px, 350px));
    padding-bottom: 4rem;
  }
`;

export const SectionTitle = styled.div`
  color: ${Palette.blue};
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;