import { useTranslation } from "react-i18next";
import { FaBars } from "react-icons/fa";
import PortugalFlag from "../../assets/flags/portugal.svg";
import UKFlag from "../../assets/flags/united_kingdom.svg";
import { OnHoverZoomClickable } from "../Animations/Motions";
import { RemainLogo } from "../Constants/Images";
import { NavigationLinks } from "../Constants/NavLinks";
import { Image, MarginLeft, MobileIcon, Nav, NavContainer, NavItem, NavLinks, NavLogo, NavMenu, TransparentButton } from "./NavbarElement";

export interface NavProps {
  toggleMenu: () => void;
  isOpen?: boolean;
}

const Navbar: React.FC<NavProps> = ({ toggleMenu }) => {
  // const [scrollingDown, setScrollingDown] = useState(false);
  // const [lastPos, setLastPos] = useState(0);

  const { t, i18n } = useTranslation();
  const changeLanguage = (language: string) => {
      i18n.changeLanguage(language);
  };
  // const isInPortuguese = i18n.language === 'pt';

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currPos = window.scrollY;
  //     const isScrollingUp = currPos < lastPos;
  
  //     setScrollingDown(isScrollingUp);
  //     setLastPos(currPos);
  //   };
  //   const handleMouseHoverNavbar = (e: any) => {
  //     if(e.y <= 160){
  //       setScrollingDown(true);
  //     }
  //   };

  //   window.addEventListener('mouseover', handleMouseHoverNavbar, false);
  //   window.addEventListener("scroll", handleScroll, false);
  //   return () => {
  //     window.addEventListener('mouseover', handleMouseHoverNavbar, false);
  //     window.removeEventListener("scroll", handleScroll, false);
  //   };
  // }, [lastPos]);

  const toggleHome = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    // <Nav
    //   animate={{
    //     top: scrollingDown ? 0 : -200,
    //   }}
    //   initial={{ top: 0 }}
    //   transition={{ top: { duration: 0.5 } }}
    // >
    <Nav
      initial={{ top: 0 }}
      transition={{ top: { duration: 0.5 } }}
    >
      <NavContainer>
        <NavLogo
          onClick={toggleHome}
          src={RemainLogo}
        />
        <MobileIcon onClick={toggleMenu}>
            <FaBars />
        </MobileIcon>
        <NavMenu>
          {NavigationLinks.map((link, index) => (
            <OnHoverZoomClickable key={index}>
              <NavItem>
                <NavLinks
                  to={link.link}
                  smooth={true}
                  spy={true}
                  duration={500}>
                    {t(link.title)}
                </NavLinks>
              </NavItem>
            </OnHoverZoomClickable>
          ))}
          <MarginLeft />
          <OnHoverZoomClickable>
            <TransparentButton
              onClick={() => changeLanguage('pt')}
            >
              <Image 
                src={ PortugalFlag } 
              />
            </TransparentButton>
          </OnHoverZoomClickable>
          <OnHoverZoomClickable>
            <TransparentButton
              onClick={() => changeLanguage('en')}
            >
              <Image 
                src={ UKFlag } 
              />
            </TransparentButton>
          </OnHoverZoomClickable>
        </NavMenu>
      </NavContainer>
    </Nav>
  );
}

export default Navbar;