import { useTranslation } from "react-i18next";
import { Service } from "../Service";
import { CardWrapper, Content, Extra, Image, Title } from "./ServiceCardElements";

const Card: React.FC<Service> = ({
    imageAlt,
    image,
    title,
    extra,
}) => {
    const {t, i18n} = useTranslation();

    return (
        <CardWrapper whileHover={{ y: -10, transition: { duration: 0.2 } }}>
            <Image src={image} alt={t(imageAlt)} />
            <Content>
                <Title>{t(title)}</Title>
                <Extra>{t(extra)}</Extra>
            </Content>
        </CardWrapper>
    );
}

export default Card;