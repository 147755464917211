import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Palette } from "../../components/Constants/Palette";

const FormControl = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
`;

const InputLabel = styled.label`
    display: block;
    text-align: start;
    font-size: 1rem;
    margin-inline-end: 0.75rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: ${Palette.backgroundDarkBlue};
`;

const InputRequiredSpan = styled.span`
    margin-inline-start: 0.25rem;
    color: ${Palette.lightRed};
`;

const InputBox = styled.input.attrs((props: {isInvalid: boolean}) => ({
    id: 'subject',
    name: 'subject'
}))<{isInvalid: boolean}>`
    width: 100%;
    min-width: 0px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    position: relative;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 2.5rem;
    border-radius: 0.375rem;
    border: 1px solid;
    border-color: ${props => props.isInvalid ? Palette.lightRed : Palette.backgroundDarkBlue};
    ${props => props.isInvalid ? `box-shadow: 0 0 0 1px ${Palette.lightRed}` : null};
    color: ${Palette.backgroundDarkBlue};

    &:focus {
        z-index: 1;
        border-color: ${Palette.darkBlue};
        box-shadow: 0 0 0 1px ${Palette.darkBlue};
    }
`;

const InputError = styled.div`
    display: flex;
    align-items: center;
    color: ${Palette.lightRed};
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: normal;
`;

export const SubjectForm = forwardRef((props: {}, ref) => {

    const { t } = useTranslation();

    const [subject, setSubject] = useState('');
    const [isEmptyError, setEmptyError] = useState(false);

    const emptyValidation = (subject: string): boolean => {
        return subject !== '';
    };

    useImperativeHandle(ref, () => ({
        validateSubject(): boolean {
            const notEmpty = emptyValidation(subject);

            if(!notEmpty){
                setEmptyError(!notEmpty);
            }
            
            return notEmpty;
        }
    }));
    
    const handleSubjectChange = (e: any) => {
        const newSubject = e.target.value;
        if(isEmptyError && emptyValidation(newSubject)){
            setEmptyError(false);
        }
        setSubject(newSubject);
    }

    return (
        <FormControl>
            <InputLabel>
                {t('Subject')}
                <InputRequiredSpan>*</InputRequiredSpan>
            </InputLabel>
            <InputBox 
                id='subject'
                type='text'
                value={subject}
                onChange={handleSubjectChange}
                isInvalid={isEmptyError}
            />
            {
                isEmptyError ? (
                    <InputError>
                        {t('Subject required')}
                    </InputError>
                ) : null
            }
        </FormControl>
    )
})