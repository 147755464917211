import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Palette } from "../../components/Constants/Palette";
import $ from 'jquery';

const InputSuccess = styled.div`
    display: flex;
    align-items: center;
    color: ${Palette.lightGreen};
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: normal;
`;

const InputError = styled.div`
    display: flex;
    align-items: center;
    color: ${Palette.lightRed};
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: normal;
`;

const Button = styled.button.attrs((props: {isLoading: boolean}) => ({
    type: 'submit'
}))<{isLoading: boolean}>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    width: auto;
    line-height: 1.2;
    border-radius: 0.375rem;
    font-weight: 600;
    height: 2.5rem;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    background: ${Palette.midBlue};
    border: none;
    color: ${Palette.white};
    margin-bottom: 0.5rem;
    ${props => props.isLoading ? `
        opacity: 0.4;
        cursor: not-allowed;
        box-shadow: none;
    ` : null}
`;

const ButtonSpan = styled.span`
    display: inline-flex;
    align-self: center;
    flex-shrink: 0;
    margin-inline-start: 0.5rem;
`;

const EmailIcon = styled.svg.attrs(props => ({
    viewBox: "0 0 24 24",
    focusable: "false"
}))`
    width: 1em;
    height: 1em;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: currentColor;
    vertical-align: middle;
`;

const SpinnerIcon = styled.div.attrs(props => ({
    className: "spinner-border spinner-border-sm"
}))``;

export const SendButton = (props: { 
    validFirstLastNames: () => boolean, 
    validEmail: () => boolean, 
    validContact: () => boolean, 
    validSubject: () => boolean,
    validMessage: () => boolean,
}) => {

    const { t } = useTranslation();

    const [isSending, setSending] = useState(false);
    const [sendingSuccess, setSendingSuccess] = useState(null);
    const [sendingError, setSendingError] = useState(null);

    const handleSendingChange = (e: any) => {
        e.preventDefault();
        const validFirstLastNames = props.validFirstLastNames();
        const validEmail = props.validEmail();
        const validContact = props.validContact();
        const validSubject = props.validSubject();
        const validMessage = props.validMessage();
        if(
            validFirstLastNames &&
            validEmail &&
            validContact &&
            validSubject &&
            validMessage
        ){
            setSending(true);
            $.ajax({
                url: 'sendmail.php',
                type: 'POST',
                data: {
                    name: $('#firstLastNames').val(),
                    email: $('#email').val(),
                    contact: $('#contact').val(),
                    subject: $('#subject').val(),
                    message: $('#message').val()
                },
                dataType: 'json',
                beforeSend: function (XMLHttpRequest) {
                    setSendingSuccess(null);
                    setSendingError(null);
                },
                success: function(json, textStatus) {
                    if(json.success){
                        setSendingSuccess(t('Sending Email Success'));
                        clearInputs();
                    } else if(json.error){
                        setSendingError(t('Sending Email Error'));
                    }
                },
                error: function(xhr, status, error){
                    setSendingError(t('Sending Email Error'));
                },
                complete: function(XMLHttpRequest, textStatus) {
                    setSending(false);
                }
              });
        }        
    };

    const clearInputs = () => {
        $('#firstLastNames').val(''); 
        $('#email').val('');
        $('#contact').val('');
        $('#subject').val('');
        $('#message').val('');
    }

    return (
        <>
            { sendingSuccess ?
                <InputSuccess>
                    { sendingSuccess }
                </InputSuccess> : null
            }
            { sendingError ?
                <InputError>
                    { sendingError }
                </InputError> : null
            }
            <Button
                isLoading={isSending}
                onClick={handleSendingChange}
            >
                { isSending ? t('Sending') : t('Send') }
                <ButtonSpan>
                    { !isSending ?
                        <EmailIcon>
                            <g fill="currentColor">
                                <path d="M11.114,14.556a1.252,1.252,0,0,0,1.768,0L22.568,4.87a.5.5,0,0,0-.281-.849A1.966,1.966,0,0,0,22,4H2a1.966,1.966,0,0,0-.289.021.5.5,0,0,0-.281.849Z"></path>
                                <path d="M23.888,5.832a.182.182,0,0,0-.2.039l-6.2,6.2a.251.251,0,0,0,0,.354l5.043,5.043a.75.75,0,1,1-1.06,1.061l-5.043-5.043a.25.25,0,0,0-.354,0l-2.129,2.129a2.75,2.75,0,0,1-3.888,0L7.926,13.488a.251.251,0,0,0-.354,0L2.529,18.531a.75.75,0,0,1-1.06-1.061l5.043-5.043a.251.251,0,0,0,0-.354l-6.2-6.2a.18.18,0,0,0-.2-.039A.182.182,0,0,0,0,6V18a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V6A.181.181,0,0,0,23.888,5.832Z"></path>
                            </g>
                        </EmailIcon>
                        :
                        <SpinnerIcon />
                    }
                </ButtonSpan>
            </Button>
        </>
    );
};