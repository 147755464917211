export interface NavLinkType {
    title: string;
    link: string;
}

export enum LinkTitle {
    Intro = 'Intro',
    Services = 'Services',
    OurMission = 'OurMission',
    Contacts = 'Contacts',
    Slider = 'Slider'
}

export enum LinkLink {
    Intro = 'intro',
    Services = 'services',
    OurMission = 'our_mission',
    Contacts = 'contacts',
    Slider = 'slider'
}

export const NavigationLinks: NavLinkType[] = [
    {
        title: LinkTitle.Services,
        link: LinkLink.Services
    },
    {
        title: LinkTitle.OurMission,
        link: LinkLink.OurMission
    },
    {
        title: LinkTitle.Contacts,
        link: LinkLink.Contacts
    },
]