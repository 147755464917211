import styled from "styled-components";
import { Palette } from "../../components/Constants/Palette";

export const IFrame = styled.iframe`
    margin: 0 auto;
    overflow: hidden;
    border: 4px solid ${Palette.darkBlue}; 
    border-radius: 20px;
    width: 70%;
    height: auto;
    aspect-ratio: 16 / 9;
    max-height: 75vh;
    display: block;
`;