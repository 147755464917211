import { motion } from "framer-motion";
import styled from "styled-components";
import { Palette } from "../../../components/Constants/Palette";

export const CardWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 350px;
  border-radius: 9px;
  background-color: ${Palette.cardDarkBlue};
  // padding: 2rem;
  &:hover {
    .title {
      color: #64ffda;
    }
  }
`;

export const Image = styled.img`
  border-radius: 5px;
`;

export const Content = styled.div`
  padding: 1.5rem;
  & > * {
    margin: 15px 0;
  }
`;

export const Title = styled.h4`
  margin-top: 0.25rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

export const Extra = styled.div`
  color: #718096;
  font-weight: 600;
  letter-spacing: 0.025em;
  font-size: 0.75rem;
  text-transform: uppercase;
  text-align: center;
`;