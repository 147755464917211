import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FadeInWhenVisible } from "../../components/Animations/Motions";
import { Palette } from "../../components/Constants/Palette";
import { SectionContainer } from "../Section/SectionContainer";
import { ContactForm } from "./ContactForm";
import { EmailForm } from "./EmailForm";
import { FirstLastNameForm } from "./FirstLastNameForm";
import { MessageForm } from "./MessageForm";
import { SendButton } from "./SendButton";
import { SubjectForm } from "./SubjectForm";

const ContactsRectangle = styled.div`
    padding: 1.5rem;
    padding-top: 2rem;
    width: 32rem;
    border-width: 1px;
    border-radius: 0.5rem;
    border-color: ${Palette.backgroundDarkBlue};
    word-wrap: break-word;
    border-style: solid;
    box-sizing: border-box;

    @media screen and (max-width: 520px) {
        width: 100%;
    }
`;

const ContactsTitle = styled.h2`
    font-size: 2.25rem;
    line-height: 1.2;
    color: ${Palette.backgroundDarkBlue};
    font-weight: 700;
    margin-bottom: 1.5rem;
`;

const Contacts = () => {
    const { t } = useTranslation();
    const centerSection = true;
    const lightBg = true;

    const firstLastCompRef = useRef<any>();
    const emailCompRef = useRef<any>();
    const contactCompRef = useRef<any>();
    const subjectCompRef = useRef<any>();
    const messageCompRef = useRef<any>();

    const validFirstLastNames = (): boolean => firstLastCompRef.current.validateFirstLastNames();
    const validEmail = (): boolean => emailCompRef.current.validateEmail();
    const validContact = (): boolean => contactCompRef.current.validateContact();
    const validSubject = (): boolean => subjectCompRef.current.validateSubject();
    const validMessage = (): boolean => messageCompRef.current.validateMessage();

    return (
        <SectionContainer 
            lightBg={lightBg} 
            verticalCenter={centerSection}
            smallScreenHeight={'fit-content'}
            fixHeightBelow745px={true}
        >
            <FadeInWhenVisible>
                <ContactsRectangle>
                    <form id="form_sendemail">
                        <ContactsTitle>
                            {t('Contact Us')}
                        </ContactsTitle>
                        <FirstLastNameForm 
                            ref={firstLastCompRef}
                        />
                        <EmailForm 
                            ref={emailCompRef}
                        />
                        <ContactForm 
                            ref={contactCompRef}
                        />
                        <SubjectForm 
                            ref={subjectCompRef}
                        />
                        <MessageForm 
                            ref={messageCompRef}
                        />
                        <SendButton 
                            validFirstLastNames={validFirstLastNames}
                            validEmail={validEmail}
                            validContact={validContact}
                            validSubject={validSubject}
                            validMessage={validMessage}
                        />
                    </form>
                </ContactsRectangle>
            </FadeInWhenVisible>
        </SectionContainer>
    );
}

export default Contacts;