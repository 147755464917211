import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { forkliftImage, latheImage, macroImage, tubeBenderImage, vehicleImage } from "../Constants/Images";
import { LinkLink } from "../Constants/NavLinks";
import { Palette } from "../Constants/Palette";

const SliderSectionContainer = styled.div`
    height: 100vh;
    display: flex;
    vertical-align: middle;
    background-color: ${Palette.backgroundDarkBlue};

    @media screen and (min-height: 746px) and (max-width: 1288px) {
        height: 700px;
    }
`;

const CarouselSlide = styled.div.attrs({
    id: 'carouselRemain',
    className: 'carousel slide',
    'data-bs-ride': 'carousel'
})`
    display: table;
    margin: 0 auto;
    width: 100%;
`;

const CarouselIndicators = styled.div.attrs({
    className: 'carousel-indicators'
})``;

const CarouselInner = styled.div.attrs({
    className: 'carousel-inner'
})`
    display: table-cell;
    vertical-align: middle;
`;

interface CarouselItemProps {
    active: boolean;
}

const CarouselItem = styled.div.attrs((props: CarouselItemProps) => ({
    className: props.active ? 'carousel-item active' : 'carousel-item'
}))<CarouselItemProps>``;

const CarouselItemImagem = styled.img.attrs(props => ({
    className: 'd-block w-80'
}))`
    margin: auto;
    border-radius: 5px;
    max-width: 768px;
    height: 100%;
    width: 100%;

    @media screen and (max-height: 449px) {
        height: 250px;
        width: auto;
    }

    @media screen and (min-height: 450px) and (max-height: 539px) {
        height: 340px;
        width: auto;
    }

    @media screen and (min-height: 540px) and (max-height: 746px) {
        height: 430px;
        width: auto;
    }
`;

const Slider = () => {
    const { t } = useTranslation();

    const images: {url: string, alt: string}[] = [
        {
            url: forkliftImage,
            alt: "forklift"
        },
        {
            url: latheImage,
            alt: "lathe"
        },
        {
            url: macroImage,
            alt: "macro"
        },
        {
            url: tubeBenderImage,
            alt: "tube-bender"
        },
        {
            url: vehicleImage,
            alt: "vehicle"
        },
    ];

    const imagesBtn = [];
    for(let i = 0; i < images.length; i++){
        imagesBtn.push(
            <button 
                type="button" 
                key={'btn-'+i}
                data-bs-target="#carouselRemain" 
                data-bs-slide-to={i} 
                className={i === 0 ? "active" : undefined} 
                aria-current={i === 0 ? "true" : undefined} 
                aria-label={"Slide "+i}
            ></button>
        );
    }

    return (
        <SliderSectionContainer>
            <CarouselSlide>
                <CarouselIndicators>
                    {imagesBtn}
                </CarouselIndicators>
                <CarouselInner>
                    {images.map((image,idx) =>
                        <CarouselItem 
                            id={'img-'+idx}
                            key={'item-'+idx}
                            active={idx === 0}
                        >
                            <CarouselItemImagem
                                id={'img-'+idx}
                                key={'img-'+idx}
                                src={image.url}
                                alt={image.alt}
                            />
                        </CarouselItem>
                    )}
                </CarouselInner>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselRemain" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">{t('Previous')}</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselRemain" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">{t('Next')}</span>
                </button>
            </CarouselSlide>
        </SliderSectionContainer>
    );
}

export default Slider;