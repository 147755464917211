import { useTranslation } from "react-i18next";
import { NavigationLinks } from "../Constants/NavLinks";
import { NavProps } from "../Navbar";
import { CloseIcon, Icon, Menu, MobileBarContainer, MobileLink, Wrapper } from "./MobileBarElements";

const MobileBar: React.FC<NavProps> = ({ toggleMenu, isOpen }) => {
    const { t, i18n } = useTranslation()

    return (
        <MobileBarContainer isOpen={isOpen} onClick={toggleMenu}>
            <Icon onClick={toggleMenu}>
                <CloseIcon />
            </Icon>
            <Wrapper>
                <Menu>
                    {NavigationLinks.map((link, index) => (
                    <MobileLink
                        key={index}
                        to={link.link}
                        onClick={toggleMenu}
                        smooth={true}
                        duration={500}>
                        {t(link.title)}
                    </MobileLink>
                    ))}
                </Menu>
            </Wrapper>
      </MobileBarContainer>
    );
}

export default MobileBar;