import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Palette } from "../../components/Constants/Palette";

const FormControl = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
`;

const InputLabel = styled.label`
    display: block;
    text-align: start;
    font-size: 1rem;
    margin-inline-end: 0.75rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: ${Palette.backgroundDarkBlue};
`;

const InputRequiredSpan = styled.span`
    margin-inline-start: 0.25rem;
    color: ${Palette.lightRed};
`;

const InputBox = styled.input.attrs((props: {isInvalid: boolean}) => ({
    id: 'contact',
    name: 'contact',
}))<{isInvalid: boolean}>`
    width: 100%;
    min-width: 0px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    position: relative;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 2.5rem;
    border-radius: 0.375rem;
    border: 1px solid;
    border-color: ${props => props.isInvalid ? Palette.lightRed : Palette.backgroundDarkBlue};
    ${props => props.isInvalid ? `box-shadow: 0 0 0 1px ${Palette.lightRed}` : null};
    color: ${Palette.backgroundDarkBlue};

    &:focus {
        z-index: 1;
        border-color: ${Palette.darkBlue};
        box-shadow: 0 0 0 1px ${Palette.darkBlue};
    }
`;

const InputError = styled.div`
    display: flex;
    align-items: center;
    color: ${Palette.lightRed};
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: normal;
`;

export const ContactForm = forwardRef((props: {}, ref) => {

    const { t } = useTranslation();

    const [contact, setContact] = useState('');
    const [isEmptyError, setEmptyError] = useState(false);
    const [isRegexError, setRegexError] = useState(false);

    const emptyValidation = (contact: string): boolean => {
        return contact !== '';
    };

    const regexValidation = (contact: string): boolean => {
        const regex = /^([9][1236])[0-9]*$/;
        const invalidStartEndWhitespaces = /^[^\s]+(\s+[^\s]+)*$/;
        return regex.test(contact) && invalidStartEndWhitespaces.test(contact);
    }
    
    useImperativeHandle(ref, () => ({
        validateContact(): boolean {
            const notEmpty = emptyValidation(contact);
            const regexValid = regexValidation(contact);

            if(!notEmpty){
                setEmptyError(!notEmpty);
            } else if(!regexValid) {
                setRegexError(!regexValid);
            }
            
            return notEmpty && regexValid;
        }
    }));

    const handleContactChange = (e: any) => {
        const newContact = e.target.value;
        if(isEmptyError && emptyValidation(newContact)){
            setEmptyError(false);
        } else if(isRegexError && regexValidation(newContact)){
            setRegexError(false);
        }
        setContact(newContact);
    };

    return (
        <FormControl>
            <InputLabel>
                {t('Contact')}
                <InputRequiredSpan>*</InputRequiredSpan>
            </InputLabel>
            <InputBox 
                id='contact'
                type="tel"
                maxLength={13}
                value={contact}
                onChange={handleContactChange}
                isInvalid={isEmptyError || isRegexError}
            />
            {isEmptyError ? (
                <InputError>
                    {t('Contact required')}
                </InputError>
            ) : isRegexError ? (
                <InputError>
                    {t('Contact not valid')}
                </InputError>
            ) : null}
        </FormControl>
    )
})