import React, { useState } from 'react';
import './App.css';
import { LinkLink } from './components/Constants/NavLinks';
import { Palette } from './components/Constants/Palette';
import MobileBar from './components/MobileBar';
import Navbar from './components/Navbar';
import Slider from './components/Slider';
import Contacts from './modules/Contacts';
import OurMission from './modules/OurMission';
import ServicesSection from './modules/ServicesSection';
import Video from './modules/Video';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar toggleMenu={toggleMenu} />
      <MobileBar isOpen={isOpen} toggleMenu={toggleMenu} />
      <div id={LinkLink.Intro}>
        <Video />
      </div>
      <div id={LinkLink.Services}>
        <div style={{height: '80px', backgroundColor: Palette.lightGray}} ></div>
        <ServicesSection />
      </div>
      <div id={LinkLink.OurMission}>
        <div style={{height: '80px', backgroundColor: Palette.backgroundDarkBlue}} ></div>
        <OurMission />
      </div>
      <div id={LinkLink.Slider}>
        <div style={{height: '80px', backgroundColor: Palette.lightGray}} ></div>
        <Slider />
      </div>
      <div id={LinkLink.Contacts}>
        <div style={{height: '80px', backgroundColor: Palette.backgroundDarkBlue}} ></div>
        <Contacts />
      </div>
    </>
  );
}

export default App;
