export const Palette = {
    "white": "#fff",
    "black": "#000",
    "lightRed": "#a40c04",
    "midBlue": "#1f75c3",
    "lightGray": "#cecece",
    "darkBlue": "#182884",
    "darkRed": "#a40c04",
    "lightBlue": "#94bee5",
    "opaqueBlue": "#7ca5d7",
    "blue": "#569de2",
    "opaqueDarkBlue": "#344494",
    "backgroundDarkBlue": "#0a192f",
    "cardDarkBlue": "#112240",
    "lightGreen": "#4BB543"
};