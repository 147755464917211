import { FadeInWhenVisible } from "../../components/Animations/Motions";
import { SectionContainer } from "../Section/SectionContainer";
import { IFrame } from "./VideoElements";

const Video = () => {
    const centerSection = false;

    return (
        <SectionContainer 
            lightBg={true} 
            verticalCenter={centerSection}
            fitContent={true}
        >
            <FadeInWhenVisible>
                <IFrame 
                    title='remain'
                    src='https://www.youtube.com/embed/AmmYXhW55Sk'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                />
            </FadeInWhenVisible>
            {/* <IconContainer variants={arrow}>
                <Link to={LinkLink.Services} smooth={true} spy={true} duration={500}>
                    <AnimateIconDown
                        animate={{ y: [0, 30, 0] }}
                        transition={{ repeat: Infinity, delay: 8 }}
                    >
                        <Icon />
                    </AnimateIconDown>
                </Link>
            </IconContainer> */}
        </SectionContainer>
    );
}

export default Video;